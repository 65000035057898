.contactForm {
  padding: 10px;
  width: 90%;
  max-width: 400px;
  margin: 30px auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactForm input,
button,
textarea {
  height: 50px;
  padding-left: 5px;
  font-size: 14px;
}
.contactForm textarea {
  height: 100px;
  font-size: 14px;
}
